import React from 'react';
import { MessageDto } from "../models/MessageDto";
import ReactMarkdown from 'react-markdown';
import { Box } from "@mui/material";

interface MessageProps {
  message: MessageDto;
  onNavigate?: (url: string) => void; // Made optional to maintain compatibility
}

const Message: React.FC<MessageProps> = ({ message, onNavigate }) => {
  // Define link click handler function
  const handleLinkClick = (href: string) => (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (onNavigate) {
      onNavigate(href);
    } else {
      window.open(href, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <Box
      sx={{
        textAlign: message.isUser ? "right" : "left",
        margin: "8px"
      }}
    >
      <Box
        sx={{
          display: 'inline-block',
          color: message.isUser ? "#ffffff" : "#000000",
          backgroundColor: message.isUser ? "#1186fe" : "#eaeaea",
          padding: "15px",
          borderRadius: "8px",
          maxWidth: "70%",
          wordBreak: "break-word"
        }}
      >
        <ReactMarkdown
          components={{
            // Corrected type for `href` and `children` in anchor tag
            a: ({ href = '', children }: { href?: string; children: React.ReactNode }) => (
              <a
                href={href}
                onClick={href ? handleLinkClick(href) : undefined}
                style={{
                  color: message.isUser ? "#ffffff" : "#0066cc",
                  textDecoration: "underline",
                  cursor: "pointer"
                }}
              >
                {children}
              </a>
            ),
          }}
        >
          {message.content}
        </ReactMarkdown>
      </Box>
    </Box>
  );
};

export default Message;
