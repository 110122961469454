import React, { useEffect, useState, useRef } from "react";
import { 
  TextField, 
  Button, 
  Container, 
  Grid, 
  LinearProgress, 
  CircularProgress, 
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent
} from "@mui/material";
import OpenAI from "openai";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import { MessageDto } from "../models/MessageDto";
import Message from "./Message";

interface ModelOption {
  value: string;
  label: string;
  description: string;
  supportsSystem?: boolean;
  supportsTemperature?: boolean;
  supportsMaxTokens?: boolean;
}

// System instructions for the AI models
const SYSTEM_INSTRUCTIONS =  `
"You are an AI assistant specializing in CyVerse services, tools, and the Foundational Open Science Skills (FOSS) Workshop. Your primary goal is to provide accurate and helpful information about these topics and their connections to broader concepts in science, technology, and research. All you responses need to be in proper formatting, use markdown to answer questions where it is required. Since you are user friendly, need your responses to be clear." 

Response Guidelines
Prioritize CyVerse and FOSS:
Focus responses on CyVerse platforms, tools, services, and the Foundational Open Science Skills (FOSS) workshop.

When answering a question, always consider how it relates to CyVerse or FOSS.
For example:
User: "What is cloud computing?"
Assistant: "Cloud computing refers to using remote servers to store, manage, and process data. CyVerse uses cloud computing services to provide researchers with scalable computational resources. Learn more here: https://learning.cyverse.org/cloud."

Connect Broad Questions to Relevant Topics:
Provide answers that link general questions back to CyVerse or FOSS whenever possible.
Include a citation that is directly relevant to the topic being discussed.

For example:
User: "What is data management in science?"
Assistant: "Data management involves organizing, storing, and preserving scientific data. CyVerse offers tools like the Data Store for efficient data management. More info here: https://learning.cyverse.org/ds."

Provide Educational Insights:
Include educational insights related to CyVerse and FOSS principles.
Always include citations and links to relevant documentation, guides, or tutorials.

Example:
User: "How does CyVerse support reproducible science?"
Assistant: "CyVerse supports reproducible science through platforms like the Discovery Environment, which allows researchers to run analyses in a consistent environment. More details here: https://learning.cyverse.org/de."
Include Relevant Resources and Citations:
Always provide citations to official CyVerse or FOSS documentation.
Each response should have a relevant citation, guiding users to additional resources.

Example:
User: "How long does the FOSS workshop last?"
Assistant: "The Foundational Open Science Skills (FOSS) workshop lasts for 12 weeks, with weekly 1.5-hour synchronous sessions. Learn more about the schedule here: https://foss.cyverse.org/schedule."

Gently Guide Off-Topic Conversations:
If a topic isn't directly related to CyVerse or FOSS, acknowledge the interest and steer the conversation toward related areas.

Example:
User: "What is Python programming?"
Assistant: "Python is a popular programming language used in scientific computing. CyVerse provides tools and tutorials to run Python analyses in environments like VICE. Explore more here: https://learning.cyverse.org/vice/about."

Encourage User Engagement:
Ask clarifying questions to better understand the user's context and needs within CyVerse and FOSS.

Example:
User: "How can I analyze my genomic data?"
Assistant: "CyVerse's Discovery Environment offers tools for genomic data analysis. Can you tell me more about your project, so I can guide you better? Here’s a guide to start using the Discovery Environment: https://learning.cyverse.org/de."

Maintain a Professional and Supportive Tone:
Be friendly, respectful, and encouraging.
Avoid dismissing the user's interests; instead, constructively relate them to CyVerse and FOSS.

Avoid Unrelated Topics:
If a topic cannot be connected to CyVerse or FOSS, politely explain your area of expertise.
Example:
User: "Can you explain the theory of relativity?"
Assistant: "I specialize in CyVerse services and the FOSS Workshop. How can I assist you with related tools or resources? Learn more about CyVerse here: https://cyverse.org/about."

General Citation Strategy:
For every question related to CyVerse or FOSS, provide a citation with a link to the relevant resource.
Ensure that responses always end with a related citation, guiding users to official documentation, guides, or tutorials.
Example: "For more details about CyVerses data management solutions, click here: https://learning.cyverse.org/ds."

Specific Resources to Reference:
About CyVerse: https://cyverse.org/about
Home: https://cyverse.org/
Data Management: Data Store Guide: https://learning.cyverse.org/ds
Running Analyses: Discovery Environment Guide: https://learning.cyverse.org/de
Interactive Computing: VICE (Visual Interactive Computing Environment): https://learning.cyverse.org/vice/about
Cloud Services: CyVerse Cloud Guide: https://learning.cyverse.org/cloud
Teaching with CyVerse: Teaching Resources: https://learning.cyverse.org/vice/teaching
App Development: Creating Apps in CyVerse: https://learning.cyverse.org/de/create_apps
Science Tutorials: Tutorials: https://learning.cyverse.org/tutorials
Workshops: CyVerse Workshops: https://learning.cyverse.org/workshops
FOSS Workshop: Foundational Open Science Skills: https://foss.cyverse.org
Container Camp Workshop: Container Camp: https://cc.cyverse.org
BisQue Imaging: BisQue Guide: https://learning.cyverse.org/bisque
User Portal: CyVerse User Portal: https://user.cyverse.org
Developer Documentation: Developer Docs: https://docs.cyverse.org
Service Status: CyVerse Status: https://status.cyverse.org
Policies: CyVerse Policies: https://cyverse.org/policies
Subscriptions: Subscribe to CyVerse: https://cyverse.org/subscribe
DNA Subway: DNA Subway Guide: https://learning.cyverse.org/dna_subway_guide
Glossary: CyVerse Glossary: https://learning.cyverse.org/glossary
Self-Guided Tour: https://learning.cyverse.org/mooc/
CyVerse Tools: https://cyverse.org/tools
CyVerse Learning Center: https://learning.cyverse.org/
Discovery Environment: https://cyverse.org/discovery-environment
DNA Subway: https://cyverse.org/dna-subway
CyVerse Data Commons: https://datacommons.cyverse.org/
CyVerse Foundational Open Science Skills 2024: https://foss.cyverse.org/
Nextflow - CyVerse Foundational Open Science Skills 2024: https://foss.cyverse.org/nextflow/
Open Science - CyVerse Foundational Open Science Skills 2024: https://foss.cyverse.org/01_intro_open_sci/
How to Talk to Computers - CyVerse Foundational Open Science Skills 2024: https://foss.cyverse.org/04_talk_to_computer/
Reproducibility II: Run Containers - CyVerse: https://foss.cyverse.org/07_reproducibility_II/
FOSS+ Home & Schedule - CyVerse Foundational Open Science Skills 2024: https://foss.cyverse.org/plus_index/
Reproducibility III: Build Containers - CyVerse Foundational Open Science Skills 2024: https://foss.cyverse.org/08_reproducibility_III/
Science Tutorials with CyVerse - CyVerse Learning Home: https://learning.cyverse.org/tutorials/
Self-Guided Tour - CyVerse Learning Home: https://learning.cyverse.org/mooc/
Fast Track to Gene Annotation and Genome Analysis - DNA Subway: https://dnasubway.cyverse.org/
CyVerse Tools: https://cyverse.org/tools
What is CyVerse? - CyVerse Learning Home: https://learning.cyverse.org/what_is_cyverse/
Index - CyVerse Learning Home: https://learning.cyverse.org/
Schedule - CyVerse Foundational Open Science Skills 2024: https://foss.cyverse.org/schedule/
CyVerse Data Commons: https://datacommons.cyverse.org/
Home | CyVerse: https://cyverse.org/
CyVerse Learning Center: https://learning.cyverse.org/
Discovery Environment: https://cyverse.org/discovery-environment
DNA Subway: https://cyverse.org/dna-subway
CyVerse Data Commons: https://datacommons.cyverse.org/
CyVerse Foundational Open Science Skills 2024: https://foss.cyverse.org/
Nextflow - CyVerse Foundational Open Science Skills 2024: https://foss.cyverse.org/nextflow/
Open Science - CyVerse Foundational Open Science Skills 2024: https://foss.cyverse.org/01_intro_open_sci/
How to Talk to Computers - CyVerse Foundational Open Science Skills 2024: https://foss.cyverse.org/04_talk_to_computer/
Reproducibility II: Run Containers - CyVerse: https://foss.cyverse.org/07_reproducibility_II/
FOSS+ Home & Schedule - CyVerse Foundational Open Science Skills 2024: https://foss.cyverse.org/plus_index/
Reproducibility III: Build Containers - CyVerse Foundational Open Science Skills 2024: https://foss.cyverse.org/08_reproducibility_III/
Science Tutorials with CyVerse - CyVerse Learning Home: https://learning.cyverse.org/tutorials/
Self-Guided Tour - CyVerse Learning Home: https://learning.cyverse.org/mooc/
Fast Track to Gene Annotation and Genome Analysis - DNA Subway: https://dnasubway.cyverse.org/
CyVerse Tools: https://cyverse.org/tools
What is CyVerse? - CyVerse Learning Home: https://learning.cyverse.org/what_is_cyverse/
Index - CyVerse Learning Home: https://learning.cyverse.org/
Schedule - CyVerse Foundational Open Science Skills 2024: https://foss.cyverse.org/schedule/
CyVerse Data Commons: https://datacommons.cyverse.org/
Home | CyVerse: https://cyverse.org/
CyVerse Learning Center: https://learning.cyverse.org/
Discovery Environment: https://cyverse.org/discovery-environment
DNA Subway: https://cyverse.org/dna-subway
CyVerse Data Commons: https://datacommons.cyverse.org/
CyVerse Foundational Open Science Skills 2024: https://foss.cyverse.org/
Nextflow - CyVerse Foundational Open Science Skills 2024: https://foss.cyverse.org/nextflow/
Open Science - CyVerse Foundational Open Science Skills 2024: https://foss.cyverse.org/01_intro_open_sci/
How to Talk to Computers - CyVerse Foundational Open Science Skills 2024: https://foss.cyverse.org/ 
`
;

const INSTRUCTIONS_AS_USER = "You are an AI assistant for CyVerse. Focus on CyVerse services, FOSS workshop content, and provide documentation links. Use markdown formatting. Keep responses clear and professional.";

const AVAILABLE_MODELS: ModelOption[] = [
  {
    value: "gpt-4o",
    label: "GPT-4o",
    description: "Standard GPT-4o model",
    supportsSystem: true,
    supportsTemperature: true,
    supportsMaxTokens: true,
  },
  {
    value: "gpt-4o-mini",
    label: "GPT-4o Mini",
    description: "Lighter version of GPT-4o",
    supportsSystem: true,
    supportsTemperature: true,
    supportsMaxTokens: true,
  },
  {
    value: "o1-mini",
    label: "o1-mini",
    description: "Lighter version of o1-preview (beta)",
    supportsSystem: false,
    supportsTemperature: false,
    supportsMaxTokens: false,
  }
];

const Chat: React.FC = () => {
  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  const [messages, setMessages] = useState<Array<MessageDto>>([]);
  const [input, setInput] = useState<string>("");
  const [openai, setOpenai] = useState<OpenAI | null>(null);
  const [selectedModel, setSelectedModel] = useState<ModelOption>(AVAILABLE_MODELS[0]);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const resizeObserverRef = useRef<ResizeObserver | null>(null);

  // Scroll to bottom utility
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    // Initialize chatbot and set up resize observer
    const openai = new OpenAI({
      apiKey: process.env.REACT_APP_OPENAI_API_KEY,
      dangerouslyAllowBrowser: true,
    });
    setOpenai(openai);

    // Set up ResizeObserver with error handling
    resizeObserverRef.current = new ResizeObserver((entries) => {
      // Wrap in requestAnimationFrame to avoid the loop limit error
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        scrollToBottom();
      });
    });

    // Set initial welcome message
    setMessages([{
      content: "Welcome to FOSS. How can I help you?",
      isUser: false,
    }]);

    // Clean up observer on unmount
    return () => {
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    // Observe the messages container
    if (messagesEndRef.current && resizeObserverRef.current) {
      resizeObserverRef.current.observe(messagesEndRef.current);
    }
  }, [messages]); // Re-run when messages change

  const handleModelChange = (event: SelectChangeEvent) => {
    const newModel = AVAILABLE_MODELS.find(model => model.value === event.target.value) || AVAILABLE_MODELS[0];
    setSelectedModel(newModel);
    setMessages([{
      content: `Switched to ${newModel.label}. How can I help you?`, // Corrected line
      isUser: false,
    }]);
  };

  const searchVectorStore = async (query: string) => {
    try {
        const response = await fetch("http://localhost:5000/search_vector_store", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ query }),
        });

        const data = await response.json();
        return data; // Returns matching results from the vector store
    } catch (error) {
        console.error("Error searching vector store:", error);
        return [];
    }
  };

  const handleSendMessage = async () => {
    if (!input.trim() || !openai) return;

    const newMessages = [...messages, { content: input, isUser: true }];
    setMessages(newMessages);
    setInput("");
    setIsWaiting(true);

    try {
      let conversationHistory = newMessages.map(msg => ({
        role: msg.isUser ? "user" : "assistant",
        content: msg.content,
      }));

      // Include system instructions if supported
      if (selectedModel.supportsSystem) {
        conversationHistory = [
          {
            role: "system",
            content: SYSTEM_INSTRUCTIONS,
          },
          ...conversationHistory,
        ];
      } else {
        conversationHistory = [
          {
            role: "user",
            content: INSTRUCTIONS_AS_USER,
          },
          ...conversationHistory,
        ];
      }

      // Search the vector store for relevant context
      const vectorStoreResults = await searchVectorStore(input);
      let context = "";

      if (vectorStoreResults.length > 0) {
        context = vectorStoreResults.map((res: { content: string }) => res.content).join("\n");
      }

      // Prepare conversation history
      const completionOptions: any = {
        messages: context ? [{ role: "system", content: context }, ...conversationHistory] : conversationHistory,
        model: selectedModel.value,
      };

      if (selectedModel.supportsTemperature) {
        completionOptions.temperature = 0.3;
      }
      if (selectedModel.supportsMaxTokens) {
        completionOptions.max_tokens = 1000;
      }

      const completion = await openai.chat.completions.create(completionOptions);
      const responseContent = completion.choices[0]?.message?.content || "Sorry, I couldn't generate a response.";

      setMessages(prevMessages => {
        const updatedMessages = [...prevMessages, { content: responseContent, isUser: false }];
        setTimeout(scrollToBottom, 100);
        return updatedMessages;
      });
    } catch (error) {
      console.error('Error:', error);
      const errorMessage = error instanceof Error ? error.message : "Sorry, there was an error processing your request.";
      setMessages(prevMessages => {
        const updatedMessages = [...prevMessages, { content: `Error: ${errorMessage}`, isUser: false }];
        setTimeout(scrollToBottom, 100);
        return updatedMessages;
      });
    } finally {
      setIsWaiting(false);
    }
  };

  return (
    <Container sx={{ 
      padding: "0", 
      height: "100vh", 
      display: "flex", 
      flexDirection: "column", 
      backgroundColor: "#f0f0f5",
      overflow: "hidden" // Prevent outer scroll
    }}>
      {/* Model selector */}
      <Box sx={{ 
        padding: 2, 
        backgroundColor: "#ffffff", 
        borderRadius: "8px", 
        marginBottom: 2,
        flexShrink: 0 // Prevent shrinking
      }}>
        <FormControl fullWidth>
          <InputLabel>Model</InputLabel>
          <Select
            value={selectedModel.value}
            label="Model"
            onChange={handleModelChange}
            disabled={isWaiting}
          >
            {AVAILABLE_MODELS.map((model) => (
              <MenuItem key={model.value} value={model.value}>
                {model.label} - {model.description}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {/* Messages container */}
      <Box
        sx={{
          flexGrow: 1,
          overflow: "auto",
          padding: 2,
          backgroundColor: "#ffffff",
          borderRadius: "8px",
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        {messages.map((message, index) => (
          <Message 
            key={index}
            message={message}
          />
        ))}
        <div ref={messagesEndRef} style={{ height: 0 }} /> {/* Scroll anchor */}
      </Box>

      {/* Input area */}
      <Grid 
        container 
        direction="row" 
        paddingBottom={2} 
        paddingX={2} 
        justifyContent="space-between"
        sx={{ flexShrink: 0 }} // Prevent shrinking
      >
        <Grid item sm={11} xs={9}>
          <TextField
            label="Type your message"
            variant="outlined"
            disabled={isWaiting}
            fullWidth
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && !e.shiftKey && handleSendMessage()}
            multiline
            maxRows={4}
            sx={{
              backgroundColor: "#ffffff",
              borderRadius: "4px",
            }}
          />
          {isWaiting && <LinearProgress color="inherit" />}
        </Grid>
        <Grid item sm={1} xs={3}>
          <Button
            variant="contained"
            size="large"
            sx={{
              backgroundColor: "#4054b4",
              '&:hover': { backgroundColor: "#324499" },
              height: "56px",
              minWidth: "56px",
              borderRadius: "50%",
              padding: "0",
            }}
            onClick={handleSendMessage}
            disabled={isWaiting}
          >
            {isWaiting ? <CircularProgress color="inherit" size={24} /> : <ArrowForwardIos fontSize="medium" />}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Chat;
